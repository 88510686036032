<template>
    <div class="Pwd">
      <h1>修改密码</h1>
      <Form ref="pwdForm" :model="formInline" :rules="ruleInline" style="width:30%;margin:auto;padding-top:10px">
          <FormItem prop="username">
              <i-input type="text" v-bind:value="formInline.username" placeholder="用户账号" disabled>
                  <Icon type="ios-person-outline" slot="prepend"></Icon>
              </i-input>
          </FormItem>
          <FormItem prop="oldpassword" >
              <i-input type="password" v-model="formInline.oldpassword" placeholder="当前密码">
                  <Icon type="ios-lock-outline" slot="prepend"></Icon>
              </i-input>
          </FormItem>
          <FormItem prop="newpassword1" >
              <i-input type="password" v-model="formInline.newpassword1" placeholder="新密码">
                  <Icon type="ios-lock-outline" slot="prepend"></Icon>
              </i-input>
          </FormItem>
          <FormItem prop="newpassword2" >
              <i-input type="password" v-model="formInline.newpassword2" placeholder="新密码">
                  <Icon type="ios-lock-outline" slot="prepend"></Icon>
              </i-input>
          </FormItem>
          <FormItem prop="yzm" >
            <Row type="flex" align="middle" justify="center">
              <i-col span="8">
                <i-input type="text" v-model="formInline.yzm" placeholder="验证码">
                    <Icon type="md-aperture" />
                </i-input>
              </i-col>
              <i-col span="1">  </i-col>
              <i-col span="8" ><yzm :identifyCode="identifyCode" v-on:onrefresh="refreshcode"></yzm></i-col>
            </Row>
          </FormItem>
          <FormItem style="padding-bottom:10px">
              <Button type="warning" @click="handleSubmit('pwdForm')" style="width:100%">修改</Button>
          </FormItem>
      </Form>
    </div>
  </template>
  
  <script>
  import {nowdatediff} from '@/zsjs/systemTool.js'
  import SIdentify from '@/components/SIdentify.vue' //* *引入验证码组件**

  export default {
    name: "ModifyPassword",
    props: {
      msg: String
    },
    data(){
        const validatepwd = (rule, value, callback) => {
            if (value == '') {
                return callback(new Error('请输入密码'))
            } else if (value != this.formInline.newpassword1) {
                return callback(new Error('两次密码不一致'))
            } else {
                callback()
            }
        }
        const validateyzm = (rule, value, callback) => {
            if (value == '') {
            return callback(new Error('请输入验证码'))
            } else if (value != this.identifyCode) {
            return callback(new Error('验证码输入错误'))
            } else {
            callback()
            }
        }        
      return {
        identifyCodes: '1234567890',
        identifyCode: '12354',
        formInline:{
          username:this._self.$root.$store.state.LogonInfo.username,
          oldpassword:null,
          newpassword1:null,
          newpassword2:null,
          yzm:null
        },
        ruleInline:{
            oldpassword: [
                { required: true, message: '请输入密码', trigger: 'blur' },
                { type: 'string', min: 8, message: '密码不能低于8位', trigger: 'blur' }
            ],
            newpassword1: [
              { required: true, message: '请输入密码', trigger: 'blur' },
              { type: 'string', min: 8, message: '密码不能低于8位', trigger: 'blur' }
            ],
            newpassword2: [
                { validator: validatepwd, required: true, trigger: 'blur' }
            ],
            yzm: [
                { validator: validateyzm, required: true, trigger: 'blur' }
            ],
            /*
            yzm: [
                { validator: validateyzm, required: true, trigger: 'blur' }
            ],
            */
        },
      }
    },
    mounted () {
      console.log(this.formInline.username)
      this.refreshcode()
    },
    components: {
      'yzm': SIdentify
    },
    methods:{
      handleSubmit(name){
        this.$refs[name].validate((valid) => {
          console.log(valid)
          if(!valid){
            this.$Message.error({content:res.data.msg, duration:3})
            return
          }
  
          this.$axios({
              method:'post',
              url:"/api/adminuser/modifypwd",
              data:{username:this.formInline.username,oldpassword:this.formInline.oldpassword,newpassword:this.formInline.newpassword1},
              headers:{'Content-Type': 'application/x-www-form-urlencoded'},
              transformRequest: function(obj) {
                  var str = [];
                  for(var p in obj){
                      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                  }
                  return str.join("&");
              }
            }).then(function(res){
              if(res.data.resultCode ==0){
                this.$Message.success({content:res.data.msg, duration:3, closable:true})
                console.log(res.data.data)
                this.formInline.oldpassword = null
                this.formInline.newpassword1 = null
                this.formInline.newpassword2 = null
                this.formInline.yzm = null
              }
              else{
                this.$Message.error({content:res.data.msg, duration:3})
              }
            }.bind(this));    
        })
      },
      randomNum (min, max) {
        return Math.floor(Math.random() * (max - min) + min)
      },
      makeCode (o, l) {
        for (let i = 0; i < l; i++) {
          this.identifyCode += this.identifyCodes[
            this.randomNum(0, this.identifyCodes.length)
          ]
        }
        console.log(this.identifyCode)
      },
      refreshcode: function () {
        this.identifyCode = ''
        this.makeCode(this.identifyCodes, 4)
      },
    }
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    FormItem {
      padding-top:10px;
    }
  </style>
  